import clsx from "clsx"
import * as styles from "./index.css"

interface Props {
  text: string
  subText?: string
  className?: string
}
export function HeadLineH1({ text, subText, className }: Props) {
  return (
    <div className={clsx(styles.container, className)}>
      <h1 className={styles.text}>{text}</h1>
      {subText !== undefined && (
        <span className={styles.subText}>{subText}</span>
      )}
    </div>
  )
}
