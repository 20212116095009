import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2Ftop%2F_components%2FPageTopCollectionListSp.css.ts.vanilla.css%22%2C%22source%22%3A%22LnlsNjE4azAgewogIHdpZHRoOiAxMDAlOwogIGRpc3BsYXk6IGdyaWQ7CiAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiBtaW5tYXgoMCwgMWZyKTsKICBnYXA6IDI0cHg7Cn0KLnlsNjE4azEgewogIGRpc3BsYXk6IGdyaWQ7CiAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiBtaW5tYXgoMCwgMWZyKTsKICBnYXA6IDE2cHg7Cn0KLnlsNjE4azIgewogIHdpZHRoOiAxMDAlOwogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBnYXA6IDI0cHg7Cn0KLnlsNjE4azMgewogIGRpc3BsYXk6IGdyaWQ7CiAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiBtaW5tYXgoMCwgMWZyKTsKICBnYXA6IDE2cHg7Cn0KLnlsNjE4azQgewogIGRpc3BsYXk6IGdyaWQ7CiAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiByZXBlYXQoMiwgMWZyKTsKICBnYXA6IDhweDsKfQoueWw2MThrNSB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGdhcDogMTZweDsKICBvdmVyZmxvdy14OiBzY3JvbGw7CiAgc2Nyb2xsYmFyLXdpZHRoOiBub25lOwogIG1hcmdpbjogMCBjYWxjKCh2YXIoLS10aWxudHAyOSkgKiAyKSAqIC0xKTsKfQoueWw2MThrNTo6LXdlYmtpdC1zY3JvbGxiYXIgewogIGRpc3BsYXk6IG5vbmU7Cn0KLnlsNjE4azYgewogIHdpZHRoOiAyMDBweDsKICBwYWRkaW5nLXJpZ2h0OiB2YXIoLS10aWxudHAyOSk7Cn0KLnlsNjE4azY6Zmlyc3QtY2hpbGQgewogIG1hcmdpbi1sZWZ0OiBjYWxjKHZhcigtLXRpbG50cDI5KSAqIDIpOwp9Ci55bDYxOGs2Omxhc3QtY2hpbGQgewogIHBhZGRpbmctcmlnaHQ6IDA7CiAgbWFyZ2luLXJpZ2h0OiBjYWxjKHZhcigtLXRpbG50cDI5KSAqIDIpOwp9Ci55bDYxOGs3IHsKICBkaXNwbGF5OiBmbGV4Owp9Ci55bDYxOGs4IHsKICBkaXNwbGF5OiBmbGV4Owp9Ci55bDYxOGs5IHsKICB3aWR0aDogMTAwJTsKICBoZWlnaHQ6IGF1dG87Cn0%3D%22%7D"
export var itemWrapper = 'yl618k8';
export var largeCardWrapper = 'yl618k2';
export var largeCardsContainer = 'yl618k1';
export var listContainer = 'yl618k0';
export var mediumCardsContainer = 'yl618k3';
export var scrollList = 'yl618k7';
export var smallCardsContainer = 'yl618k4';
export var subCopyImage = 'yl618k9';
export var xsmallCard = 'yl618k6';
export var xsmallCardsScroll = 'yl618k5';