"use client"

import { LazyImage } from "@/app/_components/LazyImage"
import { LineClamp } from "@/app/_components/LineClamp"
import { media } from "@/app/_store/atom"
import clsx from "clsx"
import { useAtomValue } from "jotai"
import * as styles from "./index.css"

interface Props {
  title: string
  description: string
  url: string
  src: string
}

export function FeatureNewsCard({ title, description, url, src }: Props) {
  const { isDesktop } = useAtomValue(media)

  return (
    <div>
      <a href={url} className={clsx(styles.featureCardContainer)}>
        <div className={clsx(styles.imageContainer)}>
          <LazyImage src={src} alt={title} className={styles.image} />
        </div>

        <div className={clsx(styles.metaContainer)}>
          <LineClamp text={title} line={1} className={styles.title} />

          {isDesktop ? (
            <LineClamp
              text={description}
              line={3}
              className={styles.description}
            />
          ) : (
            <span className={styles.description}>{description}</span>
          )}
        </div>
      </a>
    </div>
  )
}
