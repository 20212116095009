"use client"

import { Carousel } from "@/app/_components/Carousel/Carousel"
import { CollectionCard } from "@/app/_components/CollectionCard"
import { assignInlineVars } from "@vanilla-extract/dynamic"
import clsx from "clsx"
import { useMemo } from "react"
import * as styles from "./PageTopCollectionListPc.css"
import type { TopTagCollection } from "./types"

interface Props {
  collections: TopTagCollection[]
  slidesToShow?: number
  slidesToScroll?: number
}

export function PageTopCollectionListPc({
  collections,
  slidesToShow = 6,
  slidesToScroll = 6
}: Props) {
  // medium:6枚、small:12枚
  const [mediumCardCollections, smallCardCollections] = useMemo(
    () => [collections.slice(0, 6), collections.slice(6)],
    [collections]
  )

  const slideStyle = assignInlineVars({
    [styles.slideFlexVar]: `0 0 ${100 / slidesToShow}%`
  })

  if (collections.length === 0) {
    return null
  }

  return (
    <div className={styles.listContainer}>
      {mediumCardCollections.length > 0 && (
        <div className={styles.mediumCardsContainer}>
          {mediumCardCollections.map(collection => (
            <CollectionCard
              key={collection.id}
              title={collection.title}
              linkUrl={`/tag/${collection.id}`}
              mainPhoto={collection.mainPhoto}
              subPhotos={collection.subPhotos}
              users={collection.users}
              photoCount={collection.photoCount}
              size="medium"
              horizontal
            />
          ))}
        </div>
      )}

      {smallCardCollections.length > 0 && (
        <div className={styles.carouselContainer}>
          <Carousel slideToScroll={slidesToScroll} buttonTop={80}>
            {smallCardCollections.map(collection => (
              <div
                key={collection.id}
                style={slideStyle}
                className={clsx(styles.slide, styles.card)}
              >
                <CollectionCard
                  linkUrl={`/tag/${collection.id}`}
                  mainPhoto={collection.mainPhoto}
                  subPhotos={collection.subPhotos}
                  photoCount={collection.photoCount}
                  title={collection.title}
                  size="small"
                  horizontal={false}
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  )
}
