import "../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FCard%2FArticleCard%2Findex.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B1W247aMBB95yuslVYCqWaTcMuGl%2F5J5cROMAQ7cobLbrX%2FXtm5ECfOQlEfKyEB9pnx8Tkzk8x%2FLcNTEBwW6PcEoQunsIuQ73mv28nXZN5sNj98gyJlwRLAigCXEVqgNxQ40Z5B7xjPdhCh0CuuTlhgwfpnL81uIUsOXIoIKZYT4Ge2HcQgRHlZ5OQjQmnOrnohlooyhRWh%2FFRG6EzUFGPguYDCP8w0AtgVMGWJNLcRERJSmNygiGjOtOKoiZNnptJcXiK045QyYVG2BWhZZYpTHau%2FMbBjkRNgOJH56SjKCJETSOSnykBIEaGwuHuJvSETk%2BSQKXkS1N5ezpy0gr%2Bi1TCydpW8uBh7j7NZ2STiXCaH7ajTVmGOVOAjSn1n2yoiKTBleCVSABMQoZcXmxSJS5mfwEWqX41clAwGmmhhpbLJpbPqEC6AKczOTEDZVGKHn11WtgY%2BekN%2Bh1TVbf3uayUCWWCld1qxvM5uLAHkcQhwcKlq6U5cH5CzdCTv%2Bm5VdA0A2dTcHStkvNdapRwilOgasM60dbWxAgi3qmRjM2wmjf7GlCuWVDSr9um3co9mQSjlIotQsCyuGtO2fKeFci4YUTjTejEBUz%2F0KMt%2BIJXFZOr9QPVnhrzXweJ8sZqZ02aPtYdb5sq1nlMbWzVzy2XF%2FpalBAI86d%2BoGbE9PnWFtqKEN00qk%2FenEnj6gdvmTJhumC6r8F%2BNtpV2xDGMxxz01%2FbT7f0pIq2It0RxPY8GQ%2BPDOKarA7flPl92Q%2BlYqO%2Bb2FQKwJc6NpY5vZcwfZ5L5m4bkvNMYA7sWN7sRHX3YJcefIzD510OBxM6RIym64Tab0AO%2FGJM0luS4%2FPUxZNzUVXZrM6V3Vc993Pia%2FLzyCgnqEwUYwIRQdH0yAWuo97XXnGdmTzt20W004PVrOnWvuJyR6h%2B0nZvGhBz1a9OYFiHuLpQH2PeMrYVRNdD1Wn6rzU%2B%2B2lJndY4UvJPFiHfAUsegzEHbDGE7RywYAjbO2D%2BEJZ%2Fl%2B2eRZt1qC2qlsm1dW713nfOpZTjbi6lHDCXUg4JXEo5JHAp5T2mlP%2B9UjdJNuvNf0lGqmTy9QccC1JXIQ4AAA%3D%3D%22%7D"
export var articleCard = '_48u22k3';
export var badge = '_48u22kn';
export var badgeIcon = '_48u22ko';
export var categoryText = '_48u22km _48u22kl';
export var corporateNameLarge = '_48u22ki _48u22kh';
export var corporateNameSmall = '_48u22kk _48u22kj';
export var image = '_48u22k6';
export var imageWrapper = '_48u22k5';
export var large = '_48u22k2';
export var largeBottomContainer = '_48u22k9';
export var largeTitleContainer = '_48u22k8';
export var link = '_48u22k4';
export var medium = '_48u22k1';
export var small = '_48u22k0';
export var sponsordWrapper = '_48u22kg';
export var titleContainer = '_48u22k7';
export var titleLarge = '_48u22kb _48u22ka';
export var titleMedium = '_48u22kd _48u22kc';
export var titleSmall = '_48u22kf _48u22ke';