import { useEffect, useState } from "react"

/**
 * 監視対象要素が画面に表示されているかどうかを判定するカスタムフック。
 *
 * @param {React.MutableRefObject<HTMLDivElement>} ref - 監視対象のDOM要素
 * @returns {boolean} 監視対象要素が画面に表示されているかどうかを示す
 */
export const useIsIntersecting = <RefElement extends HTMLElement>(
  ref: React.MutableRefObject<RefElement | null>
): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    if (ref.current == null) return

    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting)
    })

    let observerRefCurrent: RefElement | null = null

    // 監視を開始
    observer.observe(ref.current)
    observerRefCurrent = ref.current

    return () => {
      // 要素の監視を終了する
      observer.unobserve(observerRefCurrent as RefElement)
    }
  })

  return isIntersecting
}
