"use client"

import { ArticleCard } from "@/app/_components/Card/ArticleCard"
import { Carousel } from "@/app/_components/Carousel/Carousel"
import { HeadLineH1 } from "@/app/_components/HeadLineH1"
import { TextLink } from "@/app/_components/TextLink"
import { media } from "@/app/_store/atom"
import { assignInlineVars } from "@vanilla-extract/dynamic"
import clsx from "clsx"
import { useAtomValue } from "jotai"
import * as styles from "./PageTopArticleList.css"
import type { TopMag } from "./types"

interface Props {
  articles: TopMag[]
  slidesToShow?: number
  slidesToScroll?: number
}

export function PageTopArticleList({
  articles,
  slidesToShow = 4,
  slidesToScroll = 4
}: Props) {
  if (articles.length === 0) {
    return null
  }

  const { isDesktop } = useAtomValue(media)

  const slideStyle = assignInlineVars({
    [styles.slideFlexVar]: `0 0 ${100 / slidesToShow}%`
  })

  return (
    <div>
      <div className={styles.headline}>
        <HeadLineH1 text="新着記事" />
        <TextLink
          link="https://roomclip.jp/mag"
          text="すべて見る"
          type="normal"
        />
      </div>

      <div className={styles.listContainer}>
        {/* SPは横スクロールタイプ */}
        {!isDesktop && (
          <div className={styles.scrollList}>
            <div className={styles.cardWrapper}>
              {articles.map(article => (
                <div key={article.id} className={styles.card}>
                  <ArticleCard
                    corporateName={article.companyName ?? undefined}
                    mag={{
                      id: article.id,
                      post_id: article.id,
                      custom_eyecatch: article.thumbUrl,
                      thumbUrl: article.thumbUrl,
                      title: article.articleTitle,
                      post_url: `/mag/archives/${article.id}`,
                      categoryName: article.categoryName
                    }}
                    isPr={article.is_pr}
                    size="large"
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {/* PCはカルーセルタイプ */}
        {isDesktop && (
          <Carousel slideToScroll={slidesToScroll}>
            {articles.map(article => (
              <div
                key={article.id}
                style={slideStyle}
                className={clsx(styles.slide, styles.card)}
              >
                <ArticleCard
                  corporateName={article.companyName ?? undefined}
                  mag={{
                    id: article.id,
                    post_id: article.id,
                    custom_eyecatch: article.thumbUrl,
                    thumbUrl: article.thumbUrl,
                    title: article.articleTitle,
                    post_url: `/mag/archives/${article.id}`,
                    categoryName: article.categoryName
                  }}
                  isPr={article.is_pr}
                  size="large"
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  )
}
