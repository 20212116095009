import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FCarousel%2Findex.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xYzZucHBjNCB7CiAgcG9zaXRpb246IHJlbGF0aXZlOwp9Ci5fMWM2bnBwYzUgewogIG92ZXJmbG93OiBoaWRkZW47CiAgcGFkZGluZzogMXB4Owp9Ci5fMWM2bnBwYzYgewogIGRpc3BsYXk6IGZsZXg7Cn0KLl8xYzZucHBjYiB7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHRvcDogNTAlOwogIHRyYW5zZm9ybTogdHJhbnNsYXRlKDAsIC01MCUpOwogIHotaW5kZXg6IDE7Cn0KLl8xYzZucHBjYi5fMWM2bnBwYzcgewogIGxlZnQ6IC0yMHB4OwogICYuXzFjNm5wcGNhIHsKICAgIGxlZnQ6IDhweDsKICB9CiAgJi5fMWM2bnBwYzMgewogICAgbGVmdDogdmFyKC0tXzFjNm5wcGMxKTsKICB9Cn0KLl8xYzZucHBjYi5fMWM2bnBwYzggewogIHJpZ2h0OiAtMjBweDsKICAmLl8xYzZucHBjYSB7CiAgICByaWdodDogOHB4OwogIH0KICAmLl8xYzZucHBjMyB7CiAgICByaWdodDogdmFyKC0tXzFjNm5wcGMxKTsKICB9Cn0KLl8xYzZucHBjYi5fMWM2bnBwYzkgewogIGRpc3BsYXk6IG5vbmU7Cn0KLl8xYzZucHBjYi5fMWM2bnBwYzIgewogIHRvcDogdmFyKC0tXzFjNm5wcGMwKTsKICB0cmFuc2Zvcm06IGluaXRpYWw7Cn0%3D%22%7D"
export var arrow = '_1c6nppcb';
export var buttonSideVar = 'var(--_1c6nppc1)';
export var buttonTopVar = 'var(--_1c6nppc0)';
export var container = '_1c6nppc6';
export var disabled = '_1c6nppc9';
export var embra = '_1c6nppc4';
export var next = '_1c6nppc8';
export var prev = '_1c6nppc7';
export var smallBtn = '_1c6nppca';
export var viewport = '_1c6nppc5';
export var withSideVar = '_1c6nppc3';
export var withTopVar = '_1c6nppc2';