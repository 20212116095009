"use client"

import { PhotoCardBasic } from "@/app/_components/Card/PhotoCard/PhotoCardBasic"
import { HeadLineH1 } from "@/app/_components/HeadLineH1"
import { TextLinkBody } from "@/app/_components/TextLink/TextLinkBody"
import { media } from "@/app/_store/atom"
import clsx from "clsx"
import { useAtomValue } from "jotai"
import * as styles from "./PageTopDailyRCPhotos.css"
import type { TopDailyPhotos } from "./types"

interface Props {
  dailyPhotos: TopDailyPhotos
}

export function PageTopDailyRCPhotos({ dailyPhotos }: Props) {
  const { isDesktop } = useAtomValue(media)

  return (
    <div className={styles.recommendPhotos}>
      <div className={styles.headline}>
        <HeadLineH1
          text={`Daily RoomClip vol.${dailyPhotos.volume}`}
          subText={`${dailyPhotos.volumeDate}の投稿からセレクト`}
        />
      </div>

      <div className={styles.photosContainer}>
        {dailyPhotos.photos.map((photo, index) => {
          const isLargeCard = isDesktop
            ? index >= 4 && ((index - 4) % 18 === 0 || (index - 4) % 18 === 11)
            : index % 9 === 4

          return (
            <PhotoCardBasic
              key={photo.id}
              size={isLargeCard ? "large" : "medium"}
              photo={photo}
              user={photo.user}
              className={clsx(isLargeCard && styles.largeCard)}
            />
          )
        })}
        <a className={styles.moreLink} href={`/daily/${dailyPhotos.volume}`}>
          <TextLinkBody text="もっと見る" type="arrow" />
        </a>
      </div>
    </div>
  )
}
