import { LazyImage } from "@/app/_components/LazyImage"
import { LineClamp } from "@/app/_components/LineClamp"
import { PRLabel } from "@/app/_components/PRLabel"
import type { PublishMag } from "@/app/_types"
import clsx from "clsx"
import { useMemo } from "react"
import * as styles from "./index.css"

interface Props {
  size?: "large" | "medium" | "small"
  mag?: PublishMag
  isPr?: boolean
  corporateName?: string
  isCompanyPage?: boolean
  state?: "open" // 今後他が必要になったら追加していく
}

export function ArticleCard({
  size = "medium",
  mag,
  isPr,
  corporateName,
  isCompanyPage = false,
  state
}: Props) {
  if (!mag) {
    return null
  }

  const displayTitle = useMemo(() => {
    const prefix =
      isPr && size === "small"
        ? isCompanyPage
          ? "【モニター募集】"
          : "【モニター参加】"
        : ""
    return `${prefix}${mag.title}`
  }, [isPr, mag.title, size, isCompanyPage])

  return (
    <div
      className={clsx(
        styles.articleCard,
        size === "small" && styles.small,
        size === "medium" && styles.medium,
        size === "large" && styles.large
      )}
    >
      <a
        href={mag.post_url}
        className={clsx(
          styles.link,
          size === "small" && styles.small,
          size === "large" && styles.large
        )}
      >
        <div
          className={clsx(
            styles.imageWrapper,
            size === "small" && styles.small,
            size === "medium" && styles.medium,
            size === "large" && styles.large
          )}
        >
          <LazyImage
            src={mag.custom_eyecatch}
            alt={mag.title}
            width={size === "small" ? 64 : undefined}
            height={size === "small" ? 64 : undefined}
            className={clsx(styles.image, size === "small" && styles.small)}
          />
        </div>

        {(size === "medium" || size === "small") && (
          <div
            className={clsx(
              styles.titleContainer,
              size === "small" && styles.small
            )}
          >
            <LineClamp
              text={displayTitle}
              line={size === "medium" || (size === "small" && isPr) ? 2 : 3}
              className={clsx(
                size === "small" ? styles.titleSmall : styles.titleMedium
              )}
            />
            {isPr && corporateName && (
              <div className={styles.sponsordWrapper}>
                <PRLabel color={size === "medium" ? "base00" : "base06"} />
                <LineClamp
                  text={corporateName}
                  line={1}
                  className={clsx(
                    styles.corporateNameSmall,
                    size === "medium" && styles.medium
                  )}
                />
              </div>
            )}
          </div>
        )}

        {size === "large" && (
          <div className={styles.largeTitleContainer}>
            <LineClamp
              text={displayTitle}
              line={3}
              className={clsx(styles.titleLarge)}
            />

            <div className={styles.largeBottomContainer}>
              {mag.categoryName && (
                <LineClamp
                  text={`#${mag.categoryName}`}
                  line={1}
                  className={styles.categoryText}
                />
              )}
              {isPr && corporateName && (
                <div className={styles.sponsordWrapper}>
                  <PRLabel color="base06" />
                  <LineClamp
                    text={corporateName}
                    line={1}
                    className={styles.corporateNameLarge}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {state !== undefined && (
          <span className={styles.badge}>
            {state === "open" && (
              <LazyImage
                className={styles.badgeIcon}
                src="https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/contest/badge_Open.png"
                srcset="https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/contest/badge_Open@2x.png"
                alt="募集中"
              />
            )}
          </span>
        )}
      </a>
    </div>
  )
}
