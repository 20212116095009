"use client"

import { showDesktop } from "@/app/_assets/styles/media.css"
import { useIsIntersecting } from "@/app/_hooks/intersection"
import { media } from "@/app/_store/atom"
import { clsx } from "clsx"
import { useAtomValue } from "jotai"
import Lottie, { type LottieRefCurrentProps } from "lottie-react"
import { useEffect, useRef, useState } from "react"
import * as styles from "./PageTopKeyVisual.css"
import firstView from "./_data/KeyAnimationFirstView.json"
import scrollView from "./_data/KeyAnimationScroll.json"

interface Props {
  className?: string
}

// PCのみ表示
export function PageTopKeyVisual({ className }: Props) {
  const { isDesktop } = useAtomValue(media)
  const firstViewRef = useRef<LottieRefCurrentProps>(null)
  const scrollViewRef = useRef<LottieRefCurrentProps>(null)
  const [showFirstView, setShowFirstView] = useState(true)
  const [showScrollView, setShowScrollView] = useState(false)
  // 画面内に入ったかどうかを監視する
  const observedRef = useRef<HTMLDivElement | null>(null)
  const isIntersection: boolean = useIsIntersecting<HTMLDivElement>(observedRef)

  // 画面内に入ったらアニメーションを再生
  useEffect(() => {
    if (isIntersection) {
      if (showFirstView && firstViewRef.current) {
        firstViewRef.current.play()
      }
      if (showScrollView && scrollViewRef.current) {
        scrollViewRef.current.play()
      }
    } else {
      if (showFirstView && firstViewRef.current) {
        firstViewRef.current.pause()
      }
      if (showScrollView && scrollViewRef.current) {
        scrollViewRef.current.pause()
      }
    }
  }, [isIntersection, showFirstView, showScrollView])

  // 1つ目のアニメーションが終わったら2つ目のアニメーションを再生
  const handleFirstViewComplete = () => {
    setShowFirstView(false)
    setShowScrollView(true)
  }

  if (!isDesktop) {
    return null
  }

  return (
    <div ref={observedRef} className={clsx(styles.kvContainer, showDesktop)}>
      {showFirstView && (
        <Lottie
          animationData={firstView}
          loop={false}
          renderer="svg"
          className={clsx(styles.firstView)}
          lottieRef={firstViewRef}
          onComplete={handleFirstViewComplete}
          rendererSettings={{
            progressiveLoad: true
          }}
        />
      )}
      {showScrollView && (
        <Lottie
          animationData={scrollView}
          renderer="svg"
          className={clsx(styles.scrollView)}
          lottieRef={scrollViewRef}
          rendererSettings={{
            progressiveLoad: true
          }}
        />
      )}
    </div>
  )
}
