import type {
  FilledCartItem,
  ProductWithItemId,
  Variant
} from "@/app/_types/item"
import {
  currency,
  formatCartItems,
  formatItemVariants,
  formatSalerItem,
  gtagWrap
} from "./util"

export const sendLoginEvent = (method: string): void => {
  gtagWrap("event", "login", {
    method
  })
}
export const sendSignUpEvent = (method: string): void => {
  gtagWrap("event", "sign_up", {
    method
  })
}

export const sendViewItemEventForSalerItem = (
  item: ProductWithItemId
): void => {
  gtagWrap("event", "view_item", {
    currency,
    value: item.price,
    items: formatSalerItem([item])
  })
}

export const sendViewItemEventForVariant = (item: Variant): void => {
  gtagWrap("event", "view_item", {
    currency,
    value: item.price,
    items: formatItemVariants([item])
  })
}

export function sendViewCartEvent(items: FilledCartItem[], total: number) {
  gtagWrap("event", "view_cart", {
    currency,
    value: total,
    items: formatCartItems(items)
  })
}

export function addToCartEvent(item: Variant, quantity: number) {
  gtagWrap("event", "add_to_cart", {
    currency,
    value: item.price,
    items: formatItemVariants([item], quantity),
    // 直後に画面遷移があるためbeaconにする
    transport_type: "beacon"
  })
}

export function removeFromCartEvent(item: FilledCartItem, price: number) {
  gtagWrap("event", "remove_from_cart", {
    currency,
    value: price,
    items: formatCartItems([item])
  })
}

export function addToWishlistEvent(
  item: Variant | ProductWithItemId,
  quantity: number
) {
  if ("product_id" in item) {
    // 商品詳細(アフィリエイト)
    gtagWrap("event", "add_to_wishlist", {
      currency,
      value: item.price,
      items: formatSalerItem([item])
    })
  } else {
    // 商品詳細(RCs)
    gtagWrap("event", "add_to_wishlist", {
      currency,
      value: item.price,
      items: formatItemVariants([item], quantity)
    })
  }
}

export function beginCheckoutEvent(items: FilledCartItem[], total: number) {
  gtagWrap("event", "begin_checkout", {
    currency,
    value: total,
    items: formatCartItems(items)
  })
}

export function shareSnsEvent(
  method: string,
  contentType: string,
  itemId: string
) {
  gtagWrap("event", "share", {
    method,
    content_type: contentType,
    item_id: itemId
  })
}
