import { LazyImage } from "@/app/_components/LazyImage"
import { LineClamp } from "@/app/_components/LineClamp"
import { PRLabel } from "@/app/_components/PRLabel"
import type { Contest } from "@/app/_types"
import clsx from "clsx"
import { useMemo } from "react"
import * as styles from "./index.css"

interface Props {
  event: Contest
  size?: "large" | "medium" | "small"
  isCompanyPage?: boolean
}

export function EventCard({
  event,
  size = "medium",
  isCompanyPage = false
}: Props) {
  const isPrEvent = useMemo(
    () =>
      event.detail.sponsored !== null && event.detail.corporateNames.length > 0,
    [event.detail.sponsored, event.detail.corporateNames]
  )
  const formattedCorporateNames = useMemo(
    () => event.detail.corporateNames.join(","),
    [event.detail.corporateNames]
  )

  const eventTitle = useMemo(() => {
    const prefix =
      size === "small"
        ? isCompanyPage
          ? event.detail.state === "open"
            ? "【イベント開催中】"
            : ""
          : "【イベント参加】"
        : ""
    return `${prefix}${event.detail.title_ja}`
  }, [event.detail, size, isCompanyPage])

  return (
    <a
      href={`/contest/${event.detail.id}`}
      className={clsx(
        styles.cardContainer,
        size === "small" && styles.small,
        size === "large" && styles.large
      )}
    >
      {size === "small" && (
        <div className={styles.eventInfo}>
          <LineClamp
            text={eventTitle}
            className={styles.titleSmall}
            line={isPrEvent ? 2 : 3}
          />

          {isPrEvent && (
            <div className={styles.sponsord}>
              <PRLabel />
              <LineClamp
                className={styles.corporateNameSmall}
                text={formattedCorporateNames}
                line={1}
              />
            </div>
          )}
        </div>
      )}

      <div
        className={clsx(
          styles.imageWrapper,
          size === "small" && styles.small,
          size === "medium" && styles.medium,
          size === "large" && styles.large
        )}
      >
        <LazyImage
          src={
            size === "small" || size === "large"
              ? event.detail.main_image
              : event.detail.image
          }
          alt={event.detail.title_ja}
          width={size === "small" ? 122 : undefined}
          height={size === "small" ? 64 : undefined}
          className={clsx(
            styles.eyecatch,
            size === "small" && styles.small,
            size === "large" && styles.large
          )}
        />

        {(event.detail.state === "judge" || event.detail.state === "end") && (
          <span className={styles.badge}>
            {event.detail.state === "judge" && (
              <LazyImage
                className={clsx(
                  styles.badgeIcon,
                  size === "small" && styles.small
                )}
                src="https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/contest/badge_Judge@2x.png"
                alt="審査中"
              />
            )}
            {event.detail.state === "end" && (
              <LazyImage
                className={clsx(
                  styles.badgeIcon,
                  size === "small" && styles.small
                )}
                src="https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/contest/badge_End@2x.png"
                alt="結果発表"
              />
            )}
          </span>
        )}
      </div>

      {size === "medium" && (
        <div className={styles.dateContainer}>
          {event.detail.contest_start_time}〜{event.detail.contest_end_time}
        </div>
      )}

      {size === "large" && (
        <div className={clsx(styles.eventInfo, styles.large)}>
          <LineClamp text={eventTitle} className={styles.titleLarge} line={2} />

          {/* TODO: largeのカルーセル用表示 */}

          {isPrEvent && (
            <div className={styles.sponsord}>
              <PRLabel />
              <LineClamp
                className={styles.corporateNameLarge}
                text={formattedCorporateNames}
                line={1}
              />
            </div>
          )}
        </div>
      )}
    </a>
  )
}
