import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FCollectionCard%2Findex.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2BVX0W6rOBB971dYV7pXjVS3ECgh3Jf7Kw42xC2xkXHStKv%2B%2B8rGJhgbkmj3YaWV2jgmM%2BMzM2fGw%2FOpOncizcBfDwBg2rUN%2BixALSj%2B%2FQD0CiU5tA2SBJa8OR5YV4C4Ev6vgn90BUBHyfWHFkBtAfL2rL5LcpYQk5ILJClnBWCcEfVDeRQdFwVoOWWSaMMfFMt9AeIo%2Bqm2e0LrvbT774dng9msa41dn5WqszyBZCIw65cGf8W5EACzvupzLPgscsBs7oqwIC1B8nH9pOCshljGPfwdF5gIKBCmx64AJyQeIZS0YbKN37U0PxFRNfyjAHuKMWE6AwKxjvbBd1TwaozTrFEftEV4iYXnafc5abk9T5AGSXrSCR9isGt4%2BR7wJ3I9oIxKiprAKckSRpPHpVi9haDHZk3%2FUQCiO6yknpV8IXzaVm%2BiAF2LGFirx6hrSSmhrq4CxOAFxFdL6VZO5AWqJBEaVMmZJEwW4MeP3w5GtOt4c5TkhlMp64g0ed6h8r0W%2FMi0U6oRjHFUq%2F4Q3RogOREmO9s6LvB8yjrxSfz4pOBFPfZM9Lx1ZRPwAtJrVKKri4SuU9DxhmJHKJktzS9IGSbnQuXMx%2BRQ6TY%2FknErykzXs2nI3DaZzxNfKJiWY368lxQCQTekHOxsJywfM6hFGFNW6%2BtD%2F6f2HuGtYU5DKksi0Ts2IVQBGsoIErBWKSNMPsZ5hEn95GSlXIHo5xMQ9Q49Rk%2FA%2FK00WcdFsHVYZvDBHZeSH7yYbt0%2BOBFO1o4wcu6NmZrhuzcVyorKApSKQ6FWOpjc3dVA4oUGcluH2P23OwRZYNq%2FU9Vj1t1Z89EYqFlLDVhzff38am%2F9nj3DA8N6mPTboR58a06haqtWyRodl9FwQG%2FRbD2r%2BGJtgnCCL7uGDwfwZfP4HHQutmoh0ZfO4RgNtpHBYAkcy3Np%2FhoXA75NJ47GSvXMfHh9ao0rMUzcqKE1g1SSQ1eAktiBeuimkbV1W1nX7oi9MGh5KrfMZhdYetegUsmY7mHRD5b3boSqhmhP1AoxFaTsvekPcUfmwQY1HcprI5%2B6TtV9AYde9JyONLs5za9rmjKMW6PTjr8dO0mrT9%2F1mXxeLDvp8czoyHQSCem02MyJyDGM7oBETRk0FZG7A8TkRezo3Iyu5vQ0B7ErmgYlk6uSp4AEzF2RBYRwAvG0ABGmQdEQRiv650AwRaArBSEMIIbB44EyaO6%2FrXpLXGn14Eur%2Bw79PRIr9upSsduNkd7xM%2Bz2CKurZkzSNdIsdSdNtf%2FleDs1Yd%2FRHU3ba3o8I0z5BLrxMY969Bf%2B2Cfz%2FuQTWHf6NTLsjm2jnqPS3n%2FY%2BXgeTm2UeYtKKj8nLbMfQUbab0a84kzCjn6RAqz9MxpfKvYTzQJSvq02IJV4UiIgFVupa1TdZLmiav8YnQcGv24nDA557yc85H12k%2Fe%2BXyHv1zd5Hy17f3Fzk23%2BD24O2Xz4%2Fhuderm%2FGhQAAA%3D%3D%22%7D"
export var back = 'vfxsr4d';
export var cardContainer = 'vfxsr46';
export var cardDescription = 'vfxsr4g';
export var cardUser = 'vfxsr4t';
export var center = 'vfxsr4c';
export var dummy = 'vfxsr4e';
export var dummyCover = 'vfxsr4f';
export var fixed = 'vfxsr45';
export var horizontal = 'vfxsr44';
export var iconNameWrapper = 'vfxsr49';
export var iconsWrapper = 'vfxsr4u';
export var large = 'vfxsr40';
export var largeTitle = 'vfxsr4j';
export var mainPhotoWrapper = 'vfxsr48';
export var medium = 'vfxsr41';
export var mediumTitle = 'vfxsr4l';
export var photoImage = 'vfxsr4a';
export var photosContainer = 'vfxsr47';
export var small = 'vfxsr42';
export var smallTitle = 'vfxsr4n';
export var subPhotoWrapper = 'vfxsr4b';
export var subText = 'vfxsr4s vfxsr4r';
export var textContainer = 'vfxsr4h';
export var title = 'vfxsr4i';
export var userIconMargin = 'vfxsr4v';
export var xsmall = 'vfxsr43';
export var xsmallTitle = 'vfxsr4p';