import "../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FCard%2FEventCard%2Findex.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2BVV227iMBB95yusSiuVB7dJSgOYl%2F2TlRNPiGmwI3uglFX%2FfRXnQkxNYZfHfcrFZ87MnLn46VdcLvbHvXohvyeE1NpKlFoxYqDiKPewmhAipK0r%2FsHI2kjR%2FGieFGFbVxyB5rrabZVlJC6MO%2BU1I1HzlmkjwDAS1wdidSUF2XPzSCnKSmGdTk8YariQO8s8QLxxCIQDUgG5NryNTWnl4kLDVR%2BvZyecnd6DKSr9zkgphQC1mnxOnoZ8h7fIZX45JcJ3qBu%2BEuS6REYWUX0YZ9eHk%2FH8bW30TonGXhs%2FqNn0gv%2Bk9d%2BItriVeDjxxYv8tPfSyqwCz%2B%2Fsljpnlc7ffDPGCwTjjHOtEBQy8vCw8rh4ZnW1Q8f1LgWWjMRR9GOsXf8tlQXsu%2BRb3Ypp60QqBENhDwrtRQG%2BdM84hbOKd6aZRtRbWkGBvo7dOer6y2GQNXas3NaQN2CUmpFkSZ5JHK9ud9edm0auqy6TgMtZRJ5JEl8T52167wSNwmCj7rjmdMTw6gzOu%2B6sd8b4swrqbNMkXkhkrim5VGPz16QdJ39ug3ytkOdN%2BhepzNux4kJItR4GOdDOH07YSiqgg7unmce18GUpKnBkzZMKaSBv69XuqGHhzlqXQwhRH8RmZ1EWH3QY2xyaUfJdXtxGIxDvpv9fcsrvsBVhPXgl14pKhK095UQ6WehJkxFTcSmK4%2FUoyjts5YVGD69O1P39aVo6f%2Fw3jqxr8v4uutjjm7OZ6ezSmW%2BXdlL93IKQnNjcACjClSCPW6loZ7VMo%2FowdTyni4wpjY%2Bn%2FpmyslkbDtSM0IHakgt3HY1ES7hTjZAjlUrAgRG3sT495tGCaTnPbrH76NOOo9AKqZVHYCTuVPFwywDuJYDLArhFAAcBXBzArQO4pMddq9Q8XTSVan%2Fzw1DA1%2BWXAoZ0COUX0iG5UYf0Rh2iG3WIv9fhlPA8nf9fCQ8Vnnz%2BATPrYIHcCwAA%22%7D"
export var badge = '_1h8vzvni';
export var badgeIcon = '_1h8vzvnj';
export var cardContainer = '_1h8vzvn3';
export var corporateNameLarge = '_1h8vzvnh _1h8vzvng';
export var corporateNameSmall = '_1h8vzvnf _1h8vzvne';
export var dateContainer = '_1h8vzvn7 _1h8vzvn6';
export var eventInfo = '_1h8vzvn8';
export var eyecatch = '_1h8vzvn5';
export var imageWrapper = '_1h8vzvn4';
export var large = '_1h8vzvn2';
export var medium = '_1h8vzvn1';
export var small = '_1h8vzvn0';
export var sponsord = '_1h8vzvnd';
export var titleLarge = '_1h8vzvnc _1h8vzvnb';
export var titleSmall = '_1h8vzvna _1h8vzvn9';