import clsx from "clsx"
import * as styles from "./index.css"

interface Props {
  color?: "base00" | "base06"
}

export function PRLabel({ color = "base06" }: Props) {
  return (
    <div className={clsx(styles.pr, color === "base00" && styles.base00)}>
      <span>PR</span>
    </div>
  )
}
