import type {
  FilledCartItem,
  ProductWithItemId,
  Variant
} from "@/app/_types/item"

export const currency = "JPY"

type Gtag = typeof window.gtag

// Localでgtagを宣言していないときは何もしないようにするラッパー
export const gtagWrap: Gtag = <Command extends keyof Gtag.GtagCommands>(
  command: Command,
  ...args: Gtag.GtagCommands[Command]
) => {
  if (window.gtag === undefined) {
    return
  }

  window.gtag(command, ...args)
}

// gtagのgetメソッドを使用する際の関数
// https://developers.google.com/tag-platform/gtagjs/reference#get
export function asyncGtagGet(targetId: string, fieldName: string) {
  return new Promise<
    | string
    | {
        [key: string]: unknown
      }
    // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
    | void
  >(resolve => {
    if (window.gtag === undefined) {
      resolve()
    }

    window.gtag("get", targetId, fieldName, resolve)
  })
}

export function formatItemVariants(items: Variant[], quantity = 1) {
  return items.map(item => ({
    item_id: `${item.itemId}`,
    item_name: item.title,
    item_variant: `${item.option.color}, ${item.option.size}`,
    price: item.price,
    quantity,
    currency

    // item_brand: '' // TODO
    // item_category: '' // TODO
  }))
}

export function formatCartItems(items: FilledCartItem[]) {
  return items.map(item => ({
    item_id: `${item.itemId}`,
    item_name: item.title,
    item_variant: `${item.option.color}, ${item.option.size}`,
    price: item.price,
    quantity: item.quantity,
    currency

    // item_brand: '' // TODO
    // item_category: '' // TODO
  }))
}

export function formatSalerItem(items: ProductWithItemId[]) {
  return items.map(item => ({
    item_id: `${item.itemId}`,
    item_name: item.title,
    price: item.price,
    quantity: 1,
    currency

    // item_brand: '' // TODO
    // item_category: '' // TODO
  }))
}

type GaField = "client_id" | "session_id" | "gclid"
// https://developers.google.com/tag-platform/gtagjs/reference#get
export async function getGaId(fieldName: GaField) {
  const targetId = process.env.GTAG_ID
  const gaId = ""

  if (targetId) {
    const gaId = await asyncGtagGet(targetId, fieldName)
    return gaId as string
  }
  return gaId
}
