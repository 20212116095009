"use client"

import { EventCard } from "@/app/_components/Card/EventCard"
import { Carousel } from "@/app/_components/Carousel/Carousel"
import { HeadLineH1 } from "@/app/_components/HeadLineH1"
import { TextLink } from "@/app/_components/TextLink"
import { media } from "@/app/_store/atom"
import { assignInlineVars } from "@vanilla-extract/dynamic"
import clsx from "clsx"
import { useAtomValue } from "jotai"
import * as styles from "./PageTopContestList.css"
import type { TopContest } from "./types"

interface Props {
  contests: TopContest[]
}

// TODO: carouselのデザインアップデート
export function PageTopContestList({ contests }: Props) {
  if (contests.length === 0) {
    return null
  }

  const { isDesktop } = useAtomValue(media)

  const slideStyle = assignInlineVars({
    [styles.slideFlexVar]: isDesktop ? "0 0 532px" : "0 0 90%"
  })

  return (
    <div>
      <div className={styles.headline}>
        <HeadLineH1 text="開催中のイベント" />
        <TextLink link="/contest" text="すべて見る" isNextLink type="normal" />
      </div>

      <div className={styles.carouselContainer}>
        <Carousel
          slideToScroll={1}
          loop
          buttonTop={!isDesktop ? 70 : 128}
          buttonSide={!isDesktop ? 8 : undefined}
        >
          {contests.map(contest => (
            <div
              key={contest.id}
              style={slideStyle}
              className={clsx(styles.slide)}
            >
              <EventCard
                event={{
                  detail: { ...contest },
                  awards: undefined
                }}
                size="large"
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
