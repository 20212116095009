import type { FilledCartItem, Variant } from "@/app/_types/item"
import type { PhotoObjectItem } from "@/app/photo/[id]/_components/types"
import type { MainSaler } from "@/app/shopping/products/[id]/_components/types"
import { currency, formatCartItems, formatItemVariants, gtagWrap } from "./util"

export const sendPhotoViewEvent = (photoId: number, ordinal: number): void => {
  gtagWrap("event", "photo_view", {
    photo_id: photoId,
    load_view: "web_photo",
    ordinal
  })
}

export const sendMypageViewEvent = (mypageId: number): void => {
  gtagWrap("event", "mypage_view", {
    mypage_id: mypageId
  })
}

export const sendTagViewEvent = (
  tagId: string,
  existPickupPhotos: boolean,
  existRelatedItem: boolean
): void => {
  gtagWrap("event", "tag_view", {
    tag_id: tagId,
    exist_pickup_photos: existPickupPhotos,
    exist_related_item: existRelatedItem
  })
}

export const sendItemTransferEvent = (itemId: number): void => {
  gtagWrap("event", "item_transfer", {
    item_id: itemId
  })
}

// 在庫数が0のPVが知りたいので送る
export const sendViewItemOutOfStockEventForVariant = (item: Variant): void => {
  gtagWrap("event", "view_item_out_of_stock", {
    currency,
    value: item.price,
    items: formatItemVariants([item])
  })
}

export function clickCheckoutButtonEvent(
  items: FilledCartItem[],
  total: number
) {
  gtagWrap("event", "click_checkout_button", {
    currency,
    value: total,
    items: formatCartItems(items)
  })
}

export const sendClickItemCoordinateForPocEvent = (
  item: PhotoObjectItem,
  centerX: string,
  centerY: string
): void => {
  gtagWrap("event", "click_item_coordinate_for_poc", {
    item_id: item.id,
    item_name: item.name,
    api_code: !item.isRcsProduct
      ? (item.shops.main as MainSaler).apiCode
      : "rcs",
    center_x: centerX,
    center_y: centerY
  })
}

export const sendClickItemCardForPocEvent = (
  item: PhotoObjectItem,
  index: number
): void => {
  gtagWrap("event", "click_item_card_for_poc", {
    item_id: item.id,
    item_name: item.name,
    api_code: !item.isRcsProduct
      ? (item.shops.main as MainSaler).apiCode
      : "rcs",
    card_index: index
  })
}

export const showPhotoSemiModalSheetEvent = (
  photoId: string,
  itemId: number,
  isDesktop: boolean
): void => {
  isDesktop
    ? gtagWrap("event", "show_side_sheet", { photoId, item_id: itemId })
    : gtagWrap("event", "show_bottom_sheet", { photoId, item_id: itemId })
}

export const goToAffiliateSiteFromSemiModalSheet = (
  photoId: string,
  itemId: number,
  isDesktop: boolean
): void => {
  isDesktop
    ? gtagWrap("event", "go_to_affiliate_site_from_side_sheet", {
        photoId,
        item_id: itemId
      })
    : gtagWrap("event", "go_to_affiliate_site_from_bottomsheet", {
        photoId,
        item_id: itemId
      })
}

// リフォームPoCのPhotoCardでスライドが変更された際に送信する
export const changeSlideReformPhotoCard = (
  photoId: number,
  ordinal: number,
  cardIndex: number
): void => {
  gtagWrap("event", "change_slide_reform_photo_card", {
    photo_id: photoId,
    ordinal,
    card_index: cardIndex
  })
}

export const sendFollowEvent = (followUserId: number): void => {
  gtagWrap("event", "follow", {
    follow_user_id: followUserId
  })
}

export const sendLikeEvent = (photoId: number): void => {
  gtagWrap("event", "like", {
    photo_id: photoId
  })
}

export const sendCommentEvent = (photoId: number): void => {
  gtagWrap("event", "comment", {
    photo_id: photoId
  })
}

export const sendSavePhotoEvent = (photoId: number, folderId: number): void => {
  gtagWrap("event", "save_photo", {
    photo_id: photoId,
    folder_id: folderId
  })
}

export const sendShareEvent = (
  photoId: number,
  service: "facebook" | "x" | "pinterest"
): void => {
  const eventName =
    service === "facebook"
      ? "share_to_facebook"
      : service === "x"
        ? "share_to_x"
        : "share_to_pinterest"
  gtagWrap("event", eventName, {
    photo_id: photoId
  })
}
// itemId: items.id
// location: どこの楽天送客か
// index: 関連写真の何番目に表示されたか
export const sendRakutenItemTransferEvent = (
  itemId: number,
  location: string,
  index?: number
): void => {
  gtagWrap("event", "rakuten_transfer", {
    item_id: itemId,
    location,
    index: index
  })
}

// アクティブビューの基準に基づき広告が視認可能になったときに発火するイベント
// 参考: https://support.google.com/admanager/answer/4524488?hl=ja
export const sendGptAdImpressionViewableEvent = (
  advertiserId: number | null,
  campaignId: number | null,
  lineItemId: number | null,
  creativeId: number | null,
  adUnitPath: string
): void => {
  gtagWrap("event", "gpt_ad_impression_viewable", {
    advertiserId, // 広告主ID(例: RoomClipWebAdsense)
    campaignId, // オーダーID(例: RoomClipWeb)
    lineItemId, // 広告申込情報ID
    creativeId, // クリエイティブID
    adUnitPath // 広告ユニットのパス
  })
}

export const sendGptAdClickEvent = (
  advertiserId: number | null,
  campaignId: number | null,
  lineItemId: number | null,
  creativeId: number | null,
  adUnitPath: string
): void => {
  gtagWrap("event", "gpt_ad_click", {
    advertiserId,
    campaignId,
    lineItemId,
    creativeId,
    adUnitPath
  })
}

export const sendStartMigrationCartEvent = (): void => {
  gtagWrap("event", "start_migration_cart", {})
}

export const sendEndMigrationCartEvent = (): void => {
  gtagWrap("event", "end_migration_cart", {})
}

// productsページの写真モーダル開いた際のイベント
export const showProductsPhotoModalEvent = (): void => {
  gtagWrap("event", "click_products_photo_modal", {})
}
