/**
 * startからendまでのarray listを作成する
 * example: if start=2,end=5, result=[2,3,4,5]
 *
 * @param start
 * @param end
 */
export const range = (start: number, end: number): number[] => {
  return [...Array(end - start + 1)].map((_, i) => start + i)
}

export const stringRange = (start: number, end: number): string[] => {
  return range(start, end).map(i => `${i}`)
}

export const adjustmentFill = (list: unknown[], need: number) => {
  for (let i = list.length; i < need; i++) {
    list.push(null)
  }
  return list
}

// arrayをsizeずつのarrayに分ける便利関数
// 例
// const a = splitChunk([1, 2, 3, 4, 5], 2)
// => [[1, 2], [3, 4], [5]]
export function splitChunk<T>(array: T[], size: number) {
  let result: T[][] = []
  for (let i = 0; i < array.length; i += size) {
    result = [...result, array.slice(i, i + size)]
  }
  return result
}
