"use client"

import {
  type Color as ButtonColor,
  type Size as ButtonSize,
  CarouselButton
} from "@/app/_components/CarouselButton"
import { usePrevNextButtons } from "@/app/_hooks/carousel"
import clsx from "clsx"
import useEmblaCarousel from "embla-carousel-react"

import { assignInlineVars } from "@vanilla-extract/dynamic"
import * as styles from "./index.css"

interface Props {
  children: React.ReactNode
  slideToScroll?: number
  loop?: boolean
  buttonSize?: ButtonSize
  buttonColor?: ButtonColor
  buttonTop?: number // topを直接指定するprops。
  buttonSide?: number // right, leftを直接指定するprops。
  active?: boolean
}

export function Carousel({
  children,
  slideToScroll = 6,
  loop = false,
  buttonSize = "medium",
  buttonColor = "white",
  buttonTop,
  buttonSide,
  active = true
}: Props) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: slideToScroll,
    loop,
    duration: 15,
    active
  })
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  const buttonStyle =
    buttonTop !== undefined || buttonSide !== undefined
      ? assignInlineVars({
          [styles.buttonTopVar]:
            buttonTop !== undefined ? `${buttonTop}px` : undefined,
          [styles.buttonSideVar]:
            buttonSide !== undefined ? `${buttonSide}px` : undefined
        })
      : {}

  return (
    <div className={styles.embra}>
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>{children}</div>
      </div>

      <CarouselButton
        direction="prev"
        size={buttonSize}
        color={buttonColor}
        onClick={onPrevButtonClick}
        className={clsx(
          styles.arrow,
          styles.prev,
          prevBtnDisabled && styles.disabled,
          buttonSize === "small" && styles.smallBtn,
          buttonTop !== undefined && styles.withTopVar,
          buttonSide !== undefined && styles.withSideVar
        )}
        style={buttonStyle}
      />
      <CarouselButton
        direction="next"
        size={buttonSize}
        color={buttonColor}
        onClick={onNextButtonClick}
        className={clsx(
          styles.arrow,
          styles.next,
          nextBtnDisabled && styles.disabled,
          buttonSize === "small" && styles.smallBtn,
          buttonTop !== undefined && styles.withTopVar,
          buttonSide !== undefined && styles.withSideVar
        )}
        style={buttonStyle}
      />
    </div>
  )
}
